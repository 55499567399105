<div class="ig-inline-alert-container" [class.dissmissable]="dissmissable" [class.ig-inline-alert-as-bubble]="asBubble" [class.ig-inline-alert-light-style]="lightStyle" [class.ig-inline-alert-size-large]="large" [ngClass]="[severityClass, positionClass ]" *ngIf="!closed">
  <a *ngIf="dissmissable" class="ig-inline-alert-close" (click)="close()" title="{{'close' | uitranslate}}">
    <i class="icon-a040_nack"></i>
  </a>
  <i *ngIf="!hideIcon && !customIcon"></i>
  <i *ngIf="!!customIcon" [attr.class]="'custom-icon ' + customIcon"></i>
  <span [ngClass]="{'hide-icon' : hideIcon}">
    <ng-content></ng-content>{{storybookFakeContent}}
  </span>
</div>
