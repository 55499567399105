import { Component,  Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';

export enum InlineAlertSeverity {
  error = 'error',
  warning = 'warning',
  success = 'success',
  info = 'info',
}

export enum Position {
  none = 'none',
  top = 'top',
  left = 'left',
  bottom = 'bottom',
  right = 'right'
};

@Component({
  selector: 'ig-inline-alert',
  templateUrl: './inline-alert.component.html',
  styleUrls: ['./inline-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class InlineAlertComponent {
  /**
   * Defines the severity of the alert (defaults to "warning")
   */
  @Input() severity: InlineAlertSeverity | string = InlineAlertSeverity.warning;

  /**
   * Whether there should be a "close" icon
   */
  @Input() dissmissable: boolean = false;

  /**
   * Should this alert be displayed as a bubble (border radius and shadow and also an arrow if the bubblePositionFromRelated is set)
   */
  @Input() asBubble?: boolean;

  /**
   * The position the bubble is displayed from its related element (will influence the bubble cursor)
   */
  @Input() bubblePositionFromRelated: Position | string = Position.none;

  /**
   * hide the icon
   */
  @Input() hideIcon = false;

  /**
   * icon class for custom icon
   */
  @Input() customIcon?: string;

  /**
   * large mode
   */
  @Input() large = false;

  /**
   * light style
   */
  @Input() lightStyle = false;

  /**
   * @ignore
   */
  @Input() storybookFakeContent?: string;



  /**
   * @ignore
   */
  readonly severityClasses = {
    error: 'ig-inline-alert-severity-error',
    warning: 'ig-inline-alert-severity-warning',
    success: 'ig-inline-alert-severity-success',
    info: 'ig-inline-alert-severity-info',
  };

  /**
   * @ignore
   */
  readonly positionClasses = {
    none: '',
    top: 'ig-inline-alert-position-top',
    left: 'ig-inline-alert-position-left',
    bottom: 'ig-inline-alert-position-bottom',
    right: 'ig-inline-alert-position-right',
  };

  /**
   * @ignore
   */
  closed = false;

  /**
   * @ignore
   */
  style: any = {};

  constructor(private readonly ref: ChangeDetectorRef) {

  }

  /**
   * closes the alert
   */
  close() {
    this.closed = true;
    this.ref.markForCheck();
  }

  get positionClass() {
    return _.get(this.positionClasses, this.bubblePositionFromRelated, '');
  }

  get severityClass() {
    return _.get(this.severityClasses, this.severity, 'info');
  }

}
